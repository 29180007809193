import { useEffect, useState } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import auth from '@itau-loans-www/shopping/src/services/http/auth'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const useAddressFormalization = () => {
  const { product } = useProposal()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(false)
  const { changeLeadData } = useLead()

  const productCode = product?.proposal?.productCode

  const onSubmit = (value) => {
    setSubmitLoading(true)
    analytics.track('buttonClick', {
      currentPage: 'proposta/endereco-residencial',
      detail: 'click-step-endereco-residencial',
      customLayer: {
        description: productCode
      }
    })
    changeLeadData({
      address: {
        ...value
      }
    })

    services
      .putLeadAddress(value, 'residential')
      .then(({ next_step }) => {
        goTo(next_step)
      })
      .catch((error) => {
        console.log({ error })
      })
      .finally(() => {
        setSubmitLoading(false)
      })
  }

  useEffect(() => {
    setGetLoading(true)

    analytics.track('pageLoad', {
      currentPage: 'proposta/endereco-residencial',
      customLayer: {
        description: productCode
      }
    })
    auth({}).then(async () => {
      return services
        .getLeadAddress('residential')
        .then(({ previous_step }) => {
          changeLeadData({
            previousStep: previous_step
          })
        })
        .finally(() => setGetLoading(false))
    })
  }, [])

  const stateList = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
  ]

  return {
    stateList,
    submitLoading,
    onSubmit,
    getLoading
  }
}

export default useAddressFormalization
